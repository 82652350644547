<template>
  <div>
    <v-dialog v-model="dialog" width="500" :disabled="updating">
      <v-card class="ma-auto" min-height="250" max-height="80vh">
        <v-card-title class="primary--text"
          >Updating monitoring due dates
          <v-spacer> </v-spacer>
          <v-btn v-if="updatedParcels === parcels.length" icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <v-alert type="info" class="mt-10" v-if="!loading && !parcels.length" text>
            This organization does not have parcels
          </v-alert>
          <div class="text-right">
            <v-chip
              :color="updatedParcels === parcels.length ? 'success' : 'primary'"
              v-if="updatedParcels"
            >
              {{ updatedParcels }}/{{ parcels.length }} Updated
            </v-chip>
          </div>
          <v-list nav>
            <v-list-item-group color="primary">
              <v-list-item v-for="parcel in parcels" :key="parcel.id">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ parcel.number }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-if="parcel.updated">
                      Monitoring due date:
                      <BaseTooltip> </BaseTooltip>
                      <v-tooltip color="primary" bottom>
                        <template #activator="{ on }">
                          <span v-on="on">
                            {{ parcel.lmd ? formatMdd(parcel.mdd) : 'Today' }}
                          </span>
                        </template>
                        {{ parcel.lmd ? parcel.mdd : format(new Date(), 'YYYY-MM-DD') }}
                      </v-tooltip>
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="parcel.updating || parcel.updated">
                  <v-progress-circular
                    size="25"
                    width="3"
                    v-if="parcel.updating"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-icon v-if="parcel.updated" color="primary">mdi-check</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-container>
      </v-card>
    </v-dialog>
    <v-btn class="text-capitalize my-2" color="primary" outlined @click="calculateMDD">
      Calculate Monitoring Due Dates
    </v-btn>
  </div>
</template>

<script>
import Parcel from '../../services/parcel';
const parcel = new Parcel();
import Moment from 'moment';
export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      updating: false,
      parcels: [],
      loading: true
    };
  },
  computed: {
    updatedParcels() {
      return this.parcels.filter(p => p.updated === true).length;
    },
    allUpdated() {
      return this.parcels.every(p => p.updated === true);
    }
  },
  methods: {
    formatMdd(date) {
      return Moment(date).fromNow();
    },
    format(date) {
      return Moment(date).format('YYYY-MM-DD');
    },
    async calculateMDD() {
      this.dialog = true;
      const id = this.$route.params.id;
      let parcels;
      try {
        parcels = await parcel.list({ id });
      } catch (error) {
        console.warn(error);
        return;
      }
      this.parcels = parcels.map(p => {
        return { id: p.id, updated: false, number: p.number, updating: false, lmd: null, mdd: '' };
      });
      this.loading = false;
      this.processMDD();
    },
    async processMDD() {
      for (let p in this.parcels) {
        this.parcels[p].updating = true;
        const parcel = new Parcel({ id: this.parcels[p].id });
        parcel.updateLastMonitoredDate().then(data => {
          this.parcels[p].updated = true;
          this.parcels[p].updating = false;
          this.parcels[p].lmd = data.lmd;
          this.parcels[p].mdd = data.mdd;
        });
      }
    }
  }
};
</script>

<style>
</style>
